// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/typography/typography.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/typography/typography.tsx");
  import.meta.hot.lastModified = "1723895664000";
}
// REMIX HMR END

import { cn } from "~/lib/utils";
import { cva } from "class-variance-authority";
import { motion } from "framer-motion";
const typographyVariants = cva("", {
  variants: {
    variant: {
      h1: "font-bold text-4xl lg:text-5xl 2xl:text-6xl leading-tight lg:leading-tight 2xl:leading-tight",
      h2: "font-semibold text-3xl lg:text-4xl 2xl:text-5xl leading-tight lg:leading-tight 2xl:leading-tight",
      h3: "font-medium text-2xl lg:text-3xl 2xl:text-4xl leading-tight lg:leading-tight 2xl:leading-tight",
      h4: "font-medium text-xl lg:text-2xl 2xl:text-3xl leading-tight lg:leading-tight 2xl:leading-tight",
      h5: "font-normal text-lg lg:text-xl 2xl:text-2xl leading-tight lg:leading-tight 2xl:leading-tight",
      h6: "font-light text-lg lg:text-xl leading-tight lg:leading-tight 2xl:leading-tight",
      title1: "font-medium text-2xl lg:text-3xl 2xl:text-4xl leading-normal lg:leading-normal 2xl:leading-normal",
      title2: "font-normal text-lg lg:text-xl leading-normal lg:leading-normal 2xl:leading-normal",
      body1: "font-normal text-sm lg:text-base 2xl:text-lg leading-normal lg:leading-normal 2xl:leading-normal",
      body2: "font-light text-xs lg:text-sm 2xl:text-base leading-normal lg:leading-normal 2xl:leading-normal"
    }
  },
  defaultVariants: {
    variant: "body1"
  }
});
const Typography = ({
  variant,
  children,
  className = "",
  ...props
}) => {
  switch (variant) {
    case "h1":
      return <motion.h1 className={cn(typographyVariants({
        variant,
        className
      }))} {...props}>
          {children}
        </motion.h1>;
    case "h2":
      return <motion.h2 className={cn(typographyVariants({
        variant,
        className
      }))} {...props}>
          {children}
        </motion.h2>;
    case "h3":
      return <motion.h3 className={cn(typographyVariants({
        variant,
        className
      }))} {...props}>
          {children}
        </motion.h3>;
    case "h4":
      return <motion.h4 className={cn(typographyVariants({
        variant,
        className
      }))} {...props}>
          {children}
        </motion.h4>;
    case "h5":
      return <motion.h5 className={cn(typographyVariants({
        variant,
        className
      }))} {...props}>
          {children}
        </motion.h5>;
    case "h6":
      return <motion.h6 className={cn(typographyVariants({
        variant,
        className
      }))} {...props}>
          {children}
        </motion.h6>;
    case "title1":
    case "title2":
      return <motion.p className={cn(typographyVariants({
        variant,
        className
      }))} {...props}>
          {children}
        </motion.p>;
    case "body1":
    case "body2":
      return <motion.p className={cn(typographyVariants({
        variant,
        className
      }))} {...props}>
          {children}
        </motion.p>;
    default:
      break;
  }
};
_c = Typography;
export default Typography;
var _c;
$RefreshReg$(_c, "Typography");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;